<template>
    <div class="main">
      <div style="display: flex;height: calc(100% - 10px);width: 100%;">
        <div class="left">
          <Installedcapacity :changeValue="changeValue"></Installedcapacity>
          <UsageStructure :objAll="objDown" :changeValue="changeValue"></UsageStructure>
          <CarbonrReduction :objAll="objcont" :changeValue="changeValue"></CarbonrReduction>
        </div>
        <div class="mind">
          <div style="position: absolute;top: 31%;left: 31%;" @click="clickdept('221027423')">
            <img class="icon" src="@/assets/imgs/big/icon11.png" />
            <div class="tan" v-if="show == 1" @mouseleave="leave"  style="position: absolute;top: 0px;left: 60px;width: 240px;height: 160px;color:#454545;">
                  <div class="san"></div>
                  <div class="title" style="margin-left:0px;margin-bottom:15px;font-size: 15px;color: #aba8a8;">车间</div>
                  <!-- <pai></pai> -->
                  <div class="group" style="margin-top: 0.1rem;">
                    <div class="square" style="background: #5b8ff9"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">当前用电</div>
                    <div class="group-value">{{toThousandsSeparator(data1.value1)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #f9ba08"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">光伏用电</div>
                    <div class="group-value">{{toThousandsSeparator(data1.value2)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #25b1c1"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">市电</div>
                    <div class="group-value">{{toThousandsSeparator(data1.value3)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #cb2bd5"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">节约成本</div>
                    <div class="group-value">{{toThousandsSeparator(data1.value4)}}</div>
                    <div class="group-value">(元)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #ff7301"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">碳减排</div>
                    <div class="group-value">{{toThousandsSeparator(data1.value5)}}</div>
                    <div class="group-value">(吨)</div>
                  </div>
            </div>
            <div class="tan2" v-else @mouseenter="enter(1)">
              <div class="group" style="margin-top: 0rem;">
                <div class="square" style="background: #5b8ff9"></div>
                <div class="group-value2" style="color: black;width: 65px;">当前用电</div>
                <div class="group-value">{{toThousandsSeparator(data1.value1)}}</div>
                <div class="group-value">(kWh)</div>
              </div>
            </div>
          </div>
          <div style="position: absolute;top: 21%;left: 53%;" @click="clickdept('221027390')">
            <img class="icon" src="@/assets/imgs/big/icon11.png" />
            <div class="tan" v-if="show == 2" @mouseleave="leave" style="position: absolute;top: 0px;left: 60px;width: 240px;height: 160px;color:#454545;">
                  <div class="san"></div>
                  <div class="title" style="margin-left:0px;margin-bottom:15px;font-size: 15px;color: #aba8a8;">能源楼</div>
                  <!-- <pai></pai> -->
                  <div class="group" style="margin-top: 0.1rem;">
                    <div class="square" style="background: #5b8ff9"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">当前用电</div>
                    <div class="group-value">{{toThousandsSeparator(data2.value1)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #f9ba08"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">光伏用电</div>
                    <div class="group-value">{{toThousandsSeparator(data2.value2)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #25b1c1"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">市电</div>
                    <div class="group-value">{{toThousandsSeparator(data2.value3)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #cb2bd5"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">节约成本</div>
                    <div class="group-value">{{toThousandsSeparator(data2.value4)}}</div>
                    <div class="group-value">(元)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #ff7301"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">碳减排</div>
                    <div class="group-value">{{toThousandsSeparator(data2.value5)}}</div>
                    <div class="group-value">(吨)</div>
                  </div>
            </div>
            <div class="tan2" v-else @mouseenter="enter(2)">
              <div class="group" style="margin-top: 0rem;">
                <div class="square" style="background: #5b8ff9"></div>
                <div class="group-value2" style="color: black;width: 65px;">当前用电</div>
                <div class="group-value">{{toThousandsSeparator(data2.value1)}}</div>
                <div class="group-value">(kWh)</div>
              </div>
            </div>
          </div>
          <div style="position: absolute;top: 57%;left: 47%;" @click="clickdept('221027422')">
            <img class="icon" src="@/assets/imgs/big/icon11.png" />
            <div class="tan" v-if="show == 3" @mouseleave="leave" style="position: absolute;top: 0px;left: 60px;width: 240px;height: 160px;color:#454545;">
                  <div class="san"></div>
                  <div class="title" style="margin-left:0px;margin-bottom:15px;font-size: 15px;color: #aba8a8;">行政楼</div>
                  <!-- <pai></pai> -->
                  <div class="group" style="margin-top: 0.1rem;">
                    <div class="square" style="background: #5b8ff9"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">当前用电</div>
                    <div class="group-value">{{toThousandsSeparator(data3.value1)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #f9ba08"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">光伏用电</div>
                    <div class="group-value">{{toThousandsSeparator(data3.value2)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #25b1c1"></div>
                    <div class="group-value" style="margin-right: 20px;color: black;width: 65px;">市电</div>
                    <div class="group-value">{{toThousandsSeparator(data3.value3)}}</div>
                    <div class="group-value">(kWh)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #cb2bd5"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">节约成本</div>
                    <div class="group-value">{{toThousandsSeparator(data3.value4)}}</div>
                    <div class="group-value">(元)</div>
                  </div>
                  <div class="group">
                    <div class="square" style="background: #ff7301"></div>
                    <div class="group-value2" style="margin-right: 20px;color: black;width: 65px;">碳减排</div>
                    <div class="group-value">{{toThousandsSeparator(data3.value5)}}</div>
                    <div class="group-value">(吨)</div>
                  </div>
            </div>
            <div class="tan2" v-else @mouseenter="enter(3)">
              <div class="group" style="margin-top: 0rem;">
                <div class="square" style="background: #5b8ff9"></div>
                <div class="group-value2" style="color: black;width: 65px;">当前用电</div>
                <div class="group-value">{{toThousandsSeparator(data3.value1)}}</div>
                <div class="group-value">(kWh)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <DailyEnergy :objAll="objUp" :changeValue="changeValue"></DailyEnergy>
          <StorageInformation :objSite="rightobj" ref="rightobj"></StorageInformation>
          <EnergyInformation></EnergyInformation>
        </div>
      </div>
    </div>
</template>

<script>
import EnergyInformation from "@/views/bigscreen/overview/overview/EnergyInformation.vue";
import wrapperAll from "@/views/bigscreen/subgroup/screen";
import UsageStructure from "@/views/bigscreen/overview/overview/UsageStructure.vue";
import StorageInformation from "@/views/bigscreen/overview/overview/StorageInformation.vue";
import Installedcapacity from "@/views/bigscreen/overview/overview/Installedcapacity.vue";
import CarbonrReduction from "@/views/bigscreen/overview/overview/CarbonrReduction.vue";
import DailyEnergy from "@/views/bigscreen/overview/overview/DailyEnergy.vue";
import listTable from "@/views/bigscreen/subgroup/listTable.vue";
import pieGrad from "@/views/bigscreen/subgroup/pieGrad.vue";
import atlasMap from "@/views/bigscreen/subgroup/atlasMap.vue";
import topTitle from "@/views/bigscreen/financeView/title.vue";
export default {
  created() {

  },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按年", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      data1:{},
      data2:{},
      data3:{},
      show:0,
      nameSite: 'ABB 智慧能源平台',
      isFullMode: false,
      tabList: ['本日', '本月', '本年'],
      
      objcont: {
        name: '设备连接状态',
        nameSite: 'Device Connection Status',
        url: '/api-apps/equipment/connect/status/stat',
        site: 'cont'
      },
      objDown: {
        name: '能源使用结构',
        nameSite: 'Energy Usage Structure',
        
        url: '/api-apps/eskpi/energy/structure',
        // url: '/api-apps-v2/api/v2/energy/structure',
        site: 'structure'
      },
      objUp: {
        name: '事件状态',
        nameSite: 'Event Status',
        url: '/api-apps/eventInfo/statsByStatus',
        site: 'up'
      },
      leftobj: {
        name: '最新工单',
        nameSite: 'Current Installations',
        url: '/api-apps/app/WORKORDER/list',
        site: 'left'
      },
      rightobj: {
        name: '最新事件',
        nameSite: 'Events Lists',
        url: '/api-apps/app/EVENTINFORMATION/list',
        site: 'right'
      },
      options: [{
        value: '221027388',
        label: '昆山园区'
      }, {
        value: '221027423',
        label: '车间北'
      }, {
        value: '221027422',
        label: '行政楼'
      }, {
        value: '221027390',
        label: '能源楼'
      },],
      value: '221027388',
      changeValue: '221027388'
    }
  },
  components: {
    UsageStructure,
    StorageInformation,
    EnergyInformation,
    DailyEnergy,
    wrapperAll,
    Installedcapacity,
    CarbonrReduction,
    listTable,
    pieGrad,
    atlasMap,
    topTitle
  },
  computed: {
    indexSite(){
      let index = 0
      if(this.$store.state.financetype == '按天'){
        index = 0
      }else if(this.$store.state.financetype == '按月'){
        index = 1
      }else if(this.$store.state.financetype == '按年'){
        index =2
      }
      return index
    },
  },
  methods: {
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },
    enter(index){
      this.show = index
    },
    leave(){
      this.show = 0
    },  
    clickdept(item){
      // this.$store.commit("setoverviewdept", item)
    },
    btnSite(index,item) {
      
      console.log(item)
      if(item == "本日"){
        this.$store.commit("setfinancetype", "按天")
      }else if(item =="本月"){
        this.$store.commit("setfinancetype", "按月")
      }else if(item =="本年"){
        this.$store.commit("setfinancetype", "按年")
      }
      
    },
    delCard(value) {
      console.log(value)
      this.changeValue = value
      this.$store.commit("setbigdeptcode", value)
      // this.$store.commit("bigdeptcode", value)

    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        };
      return obj;
    },
    getData() {
      let data = {
        deptCode: "221027423",
      };
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      // var url = Config.dev_url + "/api-apps/eskpi/green/energy/absorption";
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
         
          if (res.data.code == 0) {
            
           
            this.$set(this.data1,"value1",res.data.data.totalPower)
            this.$set(this.data1,"value2",res.data.data.totalGreenPower)
            this.$set(this.data1,"value3",parseInt(res.data.data.totalPower-res.data.data.totalGreenPower))
            console.log(this.data1)
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData2() {
      let data = {
        deptCode: "221027390",
      };
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      // var url = Config.dev_url + "/api-apps/eskpi/green/energy/absorption";
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
          
          if (res.data.code == 0) {
            
           
            this.$set(this.data2,"value1",res.data.data.totalPower)
            this.$set(this.data2,"value2",res.data.data.totalGreenPower)
            this.$set(this.data2,"value3",parseInt(res.data.data.totalPower-res.data.data.totalGreenPower))
            console.log(this.data1)
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData3() {
      let data = {
        deptCode: "221027422",
      };
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      // var url = Config.dev_url + "/api-apps/eskpi/green/energy/absorption";
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
           
          if (res.data.code == 0) {
            
           
            this.$set(this.data3,"value1",res.data.data.totalPower)
            this.$set(this.data3,"value2",res.data.data.totalGreenPower)
            this.$set(this.data3,"value3",parseInt(res.data.data.totalPower-res.data.data.totalGreenPower))
            console.log(this.data1)
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData4(){
      let data = {
        deptCode: "221027423",
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        // url: Config.dev_url + "/api-apps/ectricity/electric/income",
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.$set(this.data1,"value4",parseInt(result.self+result.surplus+result.storage))
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData5(){
      let data = {
        deptCode: "221027390",
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        // url: Config.dev_url + "/api-apps/ectricity/electric/income",
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.$set(this.data2,"value4",parseInt(result.self+result.surplus+result.storage))
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData6(){
      let data = {
        deptCode: "221027422",
      };
      Object.assign(data, this.transformSearch());
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios({
        // url: Config.dev_url + "/api-apps/ectricity/electric/income",
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/income",
        method: "post",
        data,
      })
        .then((res) => {
          let result = res.data.data;
          this.$set(this.data3,"value4",parseInt(result.self+result.surplus+result.storage))
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData7(){
      let data = {
        deptCode:"221027423",
      };
      Object.assign(data, this.transformSearch());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          console.log(res)
          console.log(res)
          let result = res.data.data;
          this.$set(this.data1,"value5",result.carbon)          
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
    getData8(){
      let data = {
        deptCode:"221027390",
      };
      Object.assign(data, this.transformSearch());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          console.log(res)
          console.log(res)
          let result = res.data.data;
          this.$set(this.data2,"value5",result.carbon)          
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    },
    getData9(){
      let data = {
        deptCode:"221027422",
      };
      Object.assign(data, this.transformSearch());
      
      data.queryType = data.type;
      delete data.type;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/income",
        method: "post",
        data,
      })
        .then((res) => {
          console.log(res)
          console.log(res)
          let result = res.data.data;
          this.$set(this.data3,"value5",result.carbon)          
        })
        .catch((wrong) => {})
        .finally(() => {
          
        });
    }

  },
  mounted() {
    this.getData()
    this.getData2()
    this.getData3()
    this.getData4()
    this.getData5()
    this.getData6()
    this.getData7()
    this.getData8()
    this.getData9()
    document.body.style.overflowY = 'scroll'
  }
}
</script>

<style lang="less" scoped>
.group-unit{
  margin-left: 5px;
}
.icon{
  width: 60px;
}
.square{
  width: 10px;
  height: 10px;
  margin-right: 10px;
}
.group{
  display: flex;
  align-items: center;
  margin-top: 8px;
  font-size: 15px;
}
.tan2{
  background: white;
  border-radius: 4px;
  position: absolute;
  width:2rem;
  color:#454545;
  padding:15px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 0px;
    top: 0px;
    left: 60px;
}
.tan{
  background: white;
  border-radius: 4px;
  position: absolute;
  width:2rem;
  height: 2rem;
  color:#454545;
  padding:15px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 0px;
}
.bigheard {
  background-image: url(../../../assets/imgs/big/header.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  // width: calc(100% - 62px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &>div {
    display: flex;
    align-items: center;

    &>img {
      width: 71px;
      height: 27px;
    }
  }

  .text {
    color: white;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 10px;
    margin-left: 10px;
  }
}

.bigbg {
  width: 100%;
  height: calc(100% - 38px) !important;
  background-image: url(../../../assets/imgs/big/bg3.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background: rgb(26, 46, 69);
  box-sizing: border-box;
  z-index: -2;
}

.top {
  width: 100%;
  height: 78px;

  .bigelement {
    &>div {
      width: 100%;
      height: 70px;
      position: absolute;
      top: 0px;
      display: flex;
    }
  }
}

.main {
  width: 100%;
  background-image: url(../../../assets/imgs/big/bg3.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 32px;
  height: calc(100% - 6px);
  display: flex;
  position: relative;
  flex-direction: column;
      ul {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 6px;

      li {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: right;
        color: #ffffff;
        line-height: 22px;
        padding: 12px;
        border-radius: 4px;
        cursor: pointer;
        margin: 0 3px;
      }
    }
}

.mind {
  width: 44%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &>div {
    flex: 1;
  }
}

.left {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &>div {
    width: calc(100% - 40px);
    margin-right: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
  }

  &>div:nth-child(3) {
    margin-bottom: 0
  }
}

.right {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &>div {
    width: calc(100% - 40px);
    margin-left: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
  }

  &>div:nth-child(3) {
    margin-bottom: 0
  }
}
.grouptitle{
  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}
</style>
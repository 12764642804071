<template>
  <div class="graphright" style="position: relative;">
    <!-- <div class="type-group" style="position: absolute;top: 31px;z-index: 999;">
      <span
        class="pick-type"
        v-for="(item, idx) in timetType"
        :key="idx"
        @click="changeType(item.code)"
      >
        <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
          item.name
        }}</font>
      </span>
    </div> -->
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="Information2"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
      },
      objname: {
        name: 'Energy Storage Information',
        site: '储能信息'
      },
      newarr1:[]
    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          queryType: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          queryType: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          queryType: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          queryType: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 改变类型
    changeType(type) {
      this.searchObj.TYPE = type;
      this.getData();
    },
    getData() {
      // this.detailInfo = {
      //     cost1: '51276.33',
      //     cost2: '5270.33',
      //     comparePercent1: -11.2,
      //     comparePercent2: 11.2,
      // }
      let data = {
        deptCode: this.$store.state.overviewdept,
        equipment: "121027665",
        // deptCode:"221027059",
        // equipment:"121027096"
      };
      Object.assign(data, this.transformSearch());

      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/charging/quantity",
        method: "post",
        data: data,
      })
        .then((res) => {
          console.log(res);
          this.list = res.data.data;
          let result = res.data.data;
          var chart1 = {
            categoryData: result.date,
            seriesData: [],
          };
          // let arr1 = [123, 321, 123, 131, 231, 31, 123];
          // let arr2 = [122, 421, 223, 231, 211, 31, 423];
          chart1.seriesData.push(result.充电);
          chart1.seriesData.push(result.放电);
          chart1.seriesData.push(result.放电占比);
          console.log(chart1);
          console.log(chart1);
          this.detailInfo.chart1 = chart1;
          this.initEchart();
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    initEchart() {
      let newseries = [
          {
            name: '充电',
            type: 'bar',
            barGap: '-100%',
            barWidth:7,
            itemStyle: {
            normal: {
                    barBorderRadius: 5,
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            {offset: 0, color: 'rgba(79,172,254,0.9)'},

                            {offset: 1, color: 'rgba(79,172,254,0)'}
                        ]
                    )
                }
            },
            z: -12,
            yAxisIndex: 0,
          
            data: this.detailInfo.chart1.seriesData[0]
          },
          {
            name: '放电',
            type: 'bar',
            barWidth:7,
            yAxisIndex: 0,
            itemStyle: {
            normal: {
                    
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            {offset: 0, color: 'rgba(255,111,39,1)'},

                            {offset: 1, color: 'rgba(255,111,39,1)'}
                        ]
                    )
                }
            },
            
            data: this.detailInfo.chart1.seriesData[1]
          },
          
        ]
        if(this.currenttype !='按天'){
          for(let item of this.detailInfo.chart1.seriesData[2]){
            item = item*100
          }
          newseries.push(
            {
              name: '效率',
              type: 'line',
              itemStyle: {
                color: 'rgba(105,252,189,1)'
              },
              yAxisIndex: 1,
           
              data: this.detailInfo.chart1.seriesData[2]
            }
          )
        }
      console.log(newseries)
      var dom = document.getElementById('Information2');
      let chartInit = echarts.init(dom);
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        // tooltip: {},
        grid: {
          top: '26%',
          bottom: '0%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          right: 0,
          top: "1%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        xAxis: [
          {
            type: 'category',
            data: this.detailInfo.chart1.categoryData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(kWh)',
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
               color: "white",
              fontSize: 14,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
          },
          {
            name: "",
            axisTick: {
              show: false,
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              padding:[0,0,0,25]
            },
            axisLine: {
              lineStyle: {
                color: "white",
              },
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            
            // 控制数据参数最大值和最小值
            // interval: 400,
            
          },
        ],
        series: newseries
      };
      console.log(option)
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.clear()
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    // this.initEchart()
  }
}
</script>
                
<style lang="less" scoped>
.pick-type font {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color:rgba(255,255,255,0.7);
  line-height: 18px;
}
.type-group .pick-type .isactive-type{
  color:#DEFFFF;
}
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#Information2 {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                
<template>
  <wrapperAll :isFullModeProp="isFullMode">
    <template v-slot:content>
      <div class="bigbg" ref="bigbg2">
        <div class="top">
          <div class="title">
            <div class="titleLeft">
              <div>
                <img src="../../assets/imgs/big/title.png" alt="" style="98px">
                <div style="display: flex;flex-direction: column;text-align: initial;">
                  <div class="text">{{ nameSite }}</div>
                  <div style="font-size: 15px;">LSH Smart Energy Platform</div>
                </div>
              </div>
            </div>
            <ul class="ulName">
              <li v-for="(item, index) in nameList" :key="index" @click="nameBtn(index)" :style="{
                'background': nameIndex == index ? 'linear-gradient(360deg, #3ddcfe, #76f1fb 38%, #ffffff)' : '',
                '-webkit-background-clip': nameIndex == index ? 'text' : 'none',
                'color': nameIndex == index ? 'transparent' : 'rgba(255, 255, 255, 0.80)'
              }"><i class="icon_i" v-if="nameIndex == index"><img src="../../assets/icon/icon/bj01.png" alt=""></i>
                <div class="bgtitle" style="display: flex;flex-direction: column;text-align: initial;font-size: 20px;">
                  {{ item.name }}
                  <span style="font-size: 14px;line-height: 12px;">{{item.en}}</span>
                </div>
                </li>
            </ul>
            <div class="titleRight">
              <div>
                <span style="font-family: ABBvoice_WCNSG_Rg">{{ showTime.time1 }}</span>
                <span style="font-family: ABBvoice_WCNSG_Rg">{{ showTime.time2 }}</span>
              </div>
              <div style="font-family: none !important">{{ showTime.time3 }}</div>
            </div>
          </div>
        </div>
        <big2 v-if="nameIndex == 1"></big2>
        <finance v-if="nameIndex == 2"></finance>
        <overview v-if="nameIndex == 0"></overview>
      </div>
    </template>
  </wrapperAll>
</template>

<script>
import overview from "@/views/bigscreen/overview/index.vue";
import finance from "@/views/bigscreen/financeView/finance.vue";
import topTitle from "@/views/bigscreen/financeView/title.vue";
import big2 from "@/views/bigscreen/big2.vue";
import wrapperAll from "@/views/bigscreen/subgroup/screen";
export default {
  created() {

  },
  data() {
    return {
      timer: null,
      showTime: {
        'time1': this.$moment().format('YYYY-MM-DD'),
        'time2': this.$moment().format('dddd'),
        'time3': this.$moment().format('LTS')
      },
      nameList: [{
        name:"总览驾驶舱",
        en:"Overview"
      },{
        name:"运维驾驶舱",
        en:"Operation"
      },{
        name:"财务驾驶舱",
        en:"Financial Overview"
      }],
      nameIndex: 2,
      nameSite: 'ABB 智慧能源平台',
      isFullMode: false,
      objcont: {
        name: '设备连接状态',
        nameSite: 'Device Connection Status',
        url: '/api-apps/equipment/connect/status/stat',
        site: 'cont'
      },
      objDown: {
        name: '网关连接状态',
        nameSite: 'Gateway Connection Status',
        url: '/api-apps/stationOverview/getOnlineGatewayStatus',
        site: 'down'
      },
      objUp: {
        name: '事件状态',
        nameSite: 'Event Status',
        url: '/api-apps/eventInfo/statsByStatus',
        site: 'up'
      },
      leftobj: {
        name: '最新工单',
        nameSite: 'Current Installations',
        url: '/api-apps/app/WORKORDER/list',
        site: 'left'
      },
      rightobj: {
        name: '最新事件',
        nameSite: 'Events Lists',
        url: '/api-apps/app/EVENTINFORMATION/list',
        site: 'right'
      },
      options: [{
        value: '221027388',
        label: '昆山园区'
      }, {
        value: '221027423',
        label: '车间北配电房'
      }, {
        value: '221027422',
        label: '行政楼配电房'
      }, {
        value: '221027390',
        label: '能源楼配电房'
      },],
      value: '221027388',
      changeValue: '221027388'
    }
  },
  components: {
    big2,
    wrapperAll,
    topTitle,
    finance,
    overview
  },
  computed: {

  },
  methods: {
    nameBtn(index) {
      this.nameIndex = index
    },
    delCard(value) {
      console.log(value)
      this.changeValue = value
      this.$store.commit("setbigdeptcode", value)
      // this.$store.commit("bigdeptcode", value)

    },
  },
  mounted() {
   
    let vm = this
    if(this.$route.query.type == 1){
      this.nameIndex = 0
    }else if(this.$route.query.type == 2){
      this.nameIndex = 2
    }else if(this.$route.query.type == 3){
     
      this.nameIndex = 1
    }

    setInterval(() => {
      this.showTime.time1 = this.$moment().format('YYYY-MM-DD')
      this.showTime.time2 = this.$moment().format('dddd')
      this.showTime.time3 = this.$moment().format('LTS')
    }, 1000)
    document.body.style.overflowY = 'scroll'
   
    this.timer = setInterval(() =>{ 
      console.log(vm.$store.state.timer)
      vm.$store.commit("settimer", parseInt(vm.$store.state.timer)+1)       
        // 某些定时器操作        
    }, 60000)
  },
  beforeDestroy() {
    clearInterval(this.timer);        
      this.timer = null;
  }
}
</script>

<style lang="less" scoped>
.title {
  width: 100%;
  height: 72px;
  position: relative;
  top: 0px;
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  background-image: url(../../assets/icon/icon/nav.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;

  .titleLeft {
    height: 72px;
    display: flex;

    &>div {
      text-align: center;
      display: flex;
      align-items: center;

      &>img {
        display: block;
        width: 1.4rem;
        margin: 0 12px;
      }

      .text {
        width: 333px;
        height: 40px;
        background: linear-gradient(360deg, #3ddcfe, #76f1fb 38%, #ffffff);
        -webkit-background-clip: text;
        color: transparent;
        font-size: 0.26rem;
        font-weight: 700;
        text-align: left;
        line-height: 40px;
        letter-spacing: 5px;
      }
    }
  }

  .titleRight {
    display: flex;
    align-items: center;

    &>div {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &>div:nth-child(1) {
      width: 100px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #b4dcf1;
      line-height: 20px;
    }

    &>div:nth-child(2) {
      width: 172px;
      height: 56px;
      background: linear-gradient(180deg, #ffffff 17%, #b4dcf1 30%, #9dd8ff 75%);
      -webkit-background-clip: text;
      color: transparent;
      font-size: 40px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: center;
      margin: 0 81px 0 16px;

      line-height: 56px;
    }
  }
}

.ulName {
  position: absolute;
  left: 26%;
  bottom: 0px;
  display: flex;
  cursor: pointer;



  &>li {
    font-size: 20px;
    font-family: ABBvoice CNSG, ABBvoice CNSG-Regular;
    font-weight: 400;
    text-align: left;
    line-height: 25px;
    margin: 10px 30px;
    margin-bottom: 0px;
    &>.icon_i {
      position: absolute;
      bottom: -4px;
      margin-left: -36px;
      height: 80px;
      width: 200px;
    }
  }
}

.bigheard {
  background-image: url(../../assets/imgs/big/header.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  // width: calc(100% - 62px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: -32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &>div {
    display: flex;
    align-items: center;

    &>img {
      width: 71px;
      height: 27px;
    }
  }

  .text {
    color: white;
    font-size: 30px;
    font-weight: 600;
    letter-spacing: 10px;
    margin-left: 10px;
  }
}

.bigbg {
  width: 100%;
  height: calc(100% - 2px);
  background-image: url(../../assets/imgs/big/bg_new.jpg);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background: rgb(26, 46, 69);
  box-sizing: border-box;
  z-index: -2;
}

.top {
  width: 100%;
  height: 78px;

  .bigelement {
    &>div {
      width: 100%;
      height: 70px;
      position: absolute;
      top: 0px;
      display: flex;
    }
  }
}

.main {
  width: 100%;
  padding: 0 32px;
  height: calc(100% - 77px);
  display: flex;
  justify-content: space-between;
  position: relative;
}

.mind {
  width: 44%;
  height: 100%;
  display: flex;
  flex-direction: column;

  &>div {
    flex: 1;
  }
}

.left {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &>div {
    width: calc(100% - 40px);
    margin-right: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
  }

  &>div:nth-child(3) {
    margin-bottom: 0
  }
}

.right {
  width: 28%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  &>div {
    width: calc(100% - 40px);
    margin-left: 40px;
    height: 30%;
    display: flex;
    flex-direction: column;
  }

  &>div:nth-child(3) {
    margin-bottom: 0
  }
}
.grouptitle{
  &>span {
    color: white !important;
    font-size: 18px ;
    margin-right: 10px !important;
  }
}

.site{
  display: flex;
  flex-direction: column;
}
</style>
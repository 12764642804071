<template>
  <div class="graphright">
    <siteName :objname="objname"></siteName>
    <div ref="graphright" id="Structure"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
import "echarts/theme/macarons.js";
import siteName from "../../financeView/site";
export default {
  name: 'graphright',
  props: {},
  components: { siteName },
  data() {
    return {
      timetType: [
        {
          code: "按天",
          name:this.commonLangHandler('chuneng7_day','选天', this.getZEdata)
        },
        
        {
          code: "按月",
          name:this.commonLangHandler('chuneng7_month','选月', this.getZEdata)
        },
        {
          code: "按年",
          name:this.commonLangHandler('chuneng7_year','选年', this.getZEdata)
        },
      ],
      searchObj: {
        TYPE: "按天", //按天,按周,按月,按年
        VALUE: this.$moment(), //日期
      },
      detailInfo: {
        chart1: {},
        chart2:{}
      },
      newarr1:[],
      objname: {
        name: 'Live Energy Usage',
        site: '能源使用结构'
      },

    }
  },
  watch: {
    "currentTime": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
    "currenttype": {
      handler: function (newVal, oldVal) {
        console.log(newVal)
        if (newVal) {
          this.searchObj.TYPE = newVal
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
          this.getData2()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    currentTime() {
      return this.$store.state.timer;
    },
    currenttype(){
      return this.$store.state.financetype;
    },
    currentSelectDeptInfo() {
      return this.$store.state.overviewdept;
    },
  },
  methods: {
    getData2() {
      // this.detailInfo = {
      //     cost1: '51276.33',
      //     cost2: '5270.33',
      //     comparePercent1: -11.2,
      //     comparePercent2: 11.2,
      // }
      let data = {
        deptCode: this.$store.state.overviewdept,
      };
      Object.assign(data, this.transformSearch2());
      if (this.searchObj.TYPE == "按天") {
        data.type = "day15m";
        data.queryType = data.type;
        delete data.type;
      } else {
        data.queryType = data.type;
        delete data.type;
      }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/electric/cost",
        method: "post",
        data: data,
      })
        .then((res) => {
          let result = res.data.data;
          // this.detailInfo = {}
          this.detailInfo.cost1 = result.cost1;
          this.detailInfo.cost2 = result.cost2;
          this.detailInfo.comparePercent1 = result.comparePercent1;
          this.detailInfo.comparePercent2 = result.comparePercent2;
          console.log(result);
          var chart2 = {
            categoryData: result.categoryData,
            seriesData: result.seriesData,
          };
          this.detailInfo.chart2 = chart2;
          this.initEchart();
          
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      let floatPart2 = "";
      let floatPart3 = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分

        // if(valueArray[2]){
        //   floatPart2 = valueArray[2].toString()
        // }
        // if(valueArray[3]){
        //   floatPart3 = valueArray[3].toString()
        // }
        return intPartFormat + "." + floatPart+ floatPart2+ floatPart3;
      }
      return intPartFormat + floatPart;
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day1h",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    transformSearch2: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "day15m",
          startTime: VALUE.format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "week",
          startTime: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "month",
          startTime: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endTime: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "year",
          startTime: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endTime: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    getData() {
      var url = Config.dev_url + "/api-apps-v2/api/v2/energy/renewable/replacement-ratio";
      var data = {
        // deptCode: deptCode,
        deptCode: this.$store.state.overviewdept,
        // queryType:"year",
        // startDate:"2022-1-01 00:00:00",
        // endDate: "2022-12-10 23:59:59"
      };
      Object.assign(data, this.transformSearch());
      
      data.queryType = data.type;
      delete data.type;
      this.isLoading = true;
      this.$axios
        .post(url, data)
        .then((res) => {
          // console.log(res);
          if (res.data.code == 0) {
           
            this.detailInfo = {};
            let newarr = [];
            this.detailInfo.average = res.data.data.totalRate.toString();
            this.detailInfo.greenTotal = res.data.data.totalGreenPower.toString();
            this.detailInfo.total = res.data.data.totalPower.toString();
            var chart1 = {
              categoryData: res.data.data.date,
              seriesData: res.data.data.seriesData,
            };
            this.detailInfo.chart1 = chart1;
          console.log(res.data.data)
           
          } else {
            // this.$message.warning(res.data.msg)
          }
        })
        .catch((wrong) => {})
       
    },
    initEchart() {
      var dom = document.getElementById('Structure');
      let chartInit = echarts.init(dom);
      console.log(this.detailInfo)
      let arr = []
      for(let index in this.detailInfo.chart1.seriesData[0].value){
        let value = ""
        value = (this.detailInfo.chart1.seriesData[0].value[index]-this.detailInfo.chart1.seriesData[1].value[index]).toFixed(2)
        // if(this.detailInfo.chart1.seriesData[0].value[index]&&this.detailInfo.chart1.seriesData[1].value[index]){
        //   value = this.detailInfo.chart1.seriesData[0].value[index]-this.detailInfo.chart1.seriesData[1].value[index]
        // }else if(){

        // }
        arr.push(value)
      }
      
      console.log(arr)
      var option = {
        backgroundColor: 'rgba(0,0,0,0)',
        // tooltip: {},
        grid: {
          top: '15%',
          bottom: '0%',
          left: '0%',
          right: '0%',
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          right: 0,
          top: "1%",
          icon: "rect", //图例形状
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
          },
        },
        xAxis: [
          {
            type: 'category',
            data: this.detailInfo.chart1.categoryData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "white",
            fontSize: 14,
          },
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '(kWh)',
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              axisLabel: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
              formatter(v) {
                v = v.toString();
                if (v >= 100000000000) {
                  return v.substring(0, 5) / 10 + "亿";
                } else if (v >= 10000000000) {
                  return v.substring(0, 4) / 10 + "亿";
                } else if (v >= 1000000000) {
                  return v.substring(0, 3) / 10 + "亿";
                } else if (v >= 100000000) {
                  return v.substring(0, 2) / 10 + "亿";
                } else if (v >= 10000000) {
                  return v.substring(0, 4) + "万";
                } else if (v >= 1000000) {
                  return v.substring(0, 3) + "万";
                } else if (v >= 100000) {
                  return v.substring(0, 2) + "万";
                } else if (v >= 10000) {
                  return v.substring(0, 2) / 10 + "万";
                } else if (v >= 1000) {
                  return v;
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 7) / 10 + "亿";
                } else if (v <= -100000000000) {
                  return "-" + v.substring(1, 6) / 10 + "亿";
                } else if (v <= -10000000000) {
                  return "-" + v.substring(1, 5) / 10 + "亿";
                } else if (v <= -1000000000) {
                  return "-" + v.substring(1, 4) / 10 + "亿";
                } else if (v <= -100000000) {
                  return "-" + v.substring(1, 3) / 10 + "亿";
                } else if (v <= -10000000) {
                  return "-" + v.substring(1, 5) + "万";
                } else if (v <= -1000000) {
                  return "-" + v.substring(1, 4) + "万";
                } else if (v <= -100000) {
                  return "-" + v.substring(1, 3) + "万";
                } else if (v <= -10000) {
                  return "-" + v.substring(1, 3) / 10 + "万";
                } else if (v <= -1000) {
                  return v;
                } else {
                  return v;
                }
              },
            },
            },
           
            splitLine: {
                show: false,
                lineStyle: {
                    color: '#192a44'
                },
            },
            nameTextStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "white",
              fontSize: 14,
            },
            // min: 0,
            // max: 250,
            // interval: 50,
          },
          
        ],
        series: [
          {
            name: '市电',
            type: 'line',
            showSymbol:false,
            yAxisIndex: 0,
            stack: '总量',
            areaStyle: {
              color: '#4FACFE'
            },
            lineStyle: {
              color: '#4FACFE'
            },
            
            data: arr
          },
          {
            name: '光伏',
            type: 'line',
            showSymbol:false,
            areaStyle: {
              color: '#69FCBD'
            },
            lineStyle: {
              color: '#69FCBD'
            },
            yAxisIndex: 0,
            stack: '总量',
            
            data: this.detailInfo.chart1.seriesData[1].value
          },
         
        ]
      };
      setTimeout(() => { chartInit.resize() }, 200)
      chartInit.setOption(option);
      window.addEventListener("resize", function () {
        chartInit.resize()
      });
    }
  },
  mounted() {
    this.getData()
    this.getData2()
  }
}
</script>
                
<style lang="less" scoped>
.graphright {
  width: 100%;
  height: 100%;
  ul>li {
    width: 281px;
    height: 85px;
    display: flex;
    flex-direction: column;
    background-image: url(../../../../assets/icon/icon/lang01.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

#Structure {
  width: 100%;
  height: calc(100% - 38px);
}
</style>
                